import { render, staticRenderFns } from "./default.vue?vue&type=template&id=2427c57b&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GlobalHeader: require('/codebuild/output/src2843415679/src/aisle-rocket-2022/components/storyblok/Global/Header.vue').default,GlobalFooter: require('/codebuild/output/src2843415679/src/aisle-rocket-2022/components/storyblok/Global/Footer.vue').default})
